export const SPIRIT = [
  {
    id: "1.",
    title: "우리는 어떤 의견이든 일단 경청합니다."
  },
  {
    id: "2.",
    title: "나인투원에서는 누구나 의견을 이야기할 수 있습니다."
  },
  {
    id: "3.",
    title:
      "우리는 자유롭게 일할 수 있는 회사에서 최고의 퍼포먼스가 나온다고 믿습니다."
  },
  {
    id: "4.",
    title: "우리는 스스로, 열정적으로 경쟁력을 만들어 나갑니다."
  },
  {
    id: "5.",
    title:
      "나인투원의 프로페셔널은 각자의 역할과 자부심으로 서로를 편견없이 존중하는 것입니다."
  },
  {
    id: "6.",
    title: "우리는 세상에 없던 무언가를 만들어내기 위해 즐겁게 일합니다."
  },
  {
    id: "7.",
    title: "우리는 똑똑과 열정 이전에 올바름을 갖춘 사람을 지향합니다."
  },
  {
    id: "8.",
    title: "고민과 토론은 충분히, 결정은 신속히, 실행은 지금 즉시합니다."
  },
  {
    id: "9.",
    title: "우리는 투명한 정보공유가 최선의 의사결정을 만든다고 믿습니다."
  }
];

export const PEOPLE = [
  {
    id: 0,
    name: "배지훈",
    nickname: "niel",
    position: "CEO",
    intro:
      "이동 불편이 더 이상 당연하지 않은 세상을 만들고자 일레클을 시작했습니다. 일레클과 함께하는 일상이 당연한 세상을 만들어나가고 싶습니다."
  },
  {
    id: 1,
    name: "최정완",
    nickname: "keats",
    position: "COO",
    intro: "나인투원에서 일레클 서비스를 운영하고 있는 최정완입니다."
  },
  {
    id: 2,
    name: "이제준",
    nickname: "jl",
    position: "Head of SW Development",
    intro:
      "명확하고 깔끔한 로직을 추구하는, 똑똑하지는 않지만 어려운 문제를 좋아하는, 게으르고 걷는 걸 싫어하는, 자전거 안전홍보대사 출신 개발자입니다. 여러분의 이동을 빠르고 편리하게! elecle"
  },
  {
    id: 4,
    name: "김선필",
    nickname: "spears",
    position: "Backend Engineering Lead",
    intro:
      '인류의 진화를 견인하는 "엔지니어" 들 중 한 명으로 살아가고 있어 늘 짜릿하고 행복합니다. 일레클과 일레클팀에도 저의 이런 마음이 녹아있지요. "Move or nothing!"'
  },
  {
    id: 6,
    name: "이승건",
    nickname: "begun",
    position: "Head of Business Development",
    intro:
      "Innovation = Invention * Commercialization \n 혁신을 만들기 위해 일레클의 기술력을 사업화하는 역할을 맡고 있습니다. 없어서는 안되는 서비스를 지향합니다."
  },
  {
    id: 8,
    name: "정하나",
    nickname: "lucky",
    position: "Head of Customer Service",
    intro:
      "혼자가 아닌 팀과 협동하여 목표를 이루어 낼 때 진정한 쾌감을 느낍니다. 일레클과 함께 더 큰 목표를 달성하기 위해 열심히 달리겠습니다!"
  },
  {
    id: 10,
    name: "홍창석",
    nickname: "kendric",
    position: "Operation Leader",
    intro:
      "서비스관리부 각 파트의 구성원들이 담당업무에서 최고의 성과를 낼 수 있도록 지원하고 운영전반을 관리하고 있습니다."
  },
  {
    id: 11,
    name: "이종현",
    nickname: "sena",
    position: "CTO",
    intro:
      "저는 바퀴 달리고 화면 달린 건 다 좋아합니다. 그래서 바퀴 달린 것에 화면 다는 일을 하고 있습니다:)"
  },
  {
    id: 12,
    name: "현정호",
    nickname: "neat",
    position: "Production & Sourcing Manager",
    intro:
      "나인투원에서 기구 설계 및 Fleet Sourcing 을 담당하고 있습니다. 사용자와 물리적으로 맞닿는 모든 부분을 개선하기 위해 노력합니다."
  },
  {
    id: 13,
    name: "장민수",
    nickname: "jeioter",
    position: "Embedded Engineer(FW)",
    intro:
      "임베디드 개발 5년차 장민수입니다. 사람들의 일상이동을 더 쉽고 편하게 만들기 위해 노력합니다."
  },
  {
    id: 16,
    name: "장민순",
    nickname: "jeongal",
    position: "Embedded Engineer(FW)",
    intro: "좋은 서비스를 위해 최선을 다하겠습니다!"
  },
  {
    id: 20,
    name: "원민중",
    nickname: "lavina",
    position: "Product Manager",
    intro:
      "유저와 비즈니스 모두 만족시키는 프로덕트를 추구합니다. 그런데 저는 유저를 더 사랑해요."
  },

  {
    id: 21,
    name: "박의연",
    nickname: "edwin",
    position: "Data Specialist",
    intro:
      "데이터를 통해 세상을 바라보고, 소통하는 것이 즐거운 박의연입니다. 어려운 문제를 고민하고 해결하며 성장하는 것을 좋아해요."
  },

  {
    id: 22,
    name: "백지민",
    nickname: "mz",
    position: "Business Development Manager",
    intro:
      "새로운 시도와 문제를 해결해 나가는 과정을 좋아합니다. 일레클이 더 많은 사람들의 일상 속에 스며들 수 있도록 사업적인 방법을 고민하고 있어요."
  },

  {
    id: 23,
    name: "엄윤경",
    nickname: "dochi",
    position: "Product Manager",
    intro:
      "사람들의 고민과 이야기를 듣고 같이 해결해나가는 것을 좋아합니다. 평범한 일상에서 아이디어와 영감을 얻을 때 늘 짜릿합니다..!"
  },
  {
    id: 30,
    name: "김건우",
    nickname: "slinky",
    position: "Android Engineer",
    intro: "안드로이드 개발자 김건우입니다. 누구나 사용하기 쉬운 앱을 만듭니다!"
  },
  {
    id: 31,
    name: "이주석",
    nickname: "saturn",
    position: "Supply Chain Manager",
    intro:
      "지리와 교통수단에 관심이 많아 어느곳으로든 편하고 빠르게 이동할 수 있도록 물류에 발을 들였습니다. 전국 어디에서나 일레클을 즐겁게 이용하실 수 있도록 돕겠습니다."
  },
  {
    id: 35,
    name: "김민해",
    nickname: "tombo",
    position: "Product Designer",
    intro: "일레클에서의 경험을 디자인하는 김민해입니다. 따르릉!"
  },
  {
    id: 36,
    name: "진수철",
    nickname: "road",
    position: "Operation Planning Manager",
    intro:
      "운영기획을 담당하고 있습니다. 일레클을 이용하는 모든 순간을 즐겁게 만드는것이 목표입니다."
  },
  {
    id: 37,
    name: "장승화",
    nickname: "lorang",
    position: "Operation Planning Manager",
    intro:
      "나인투원의 운영과 기획을 담당하고 있습니다. 고객의 진짜 문제를 찾고 해결하는 것을 지향합니다."
  },
  {
    id: 38,
    name: "최민석",
    nickname: "liljerry",
    position: "Business Development Analyst",
    intro:
      "어제보다 나은 오늘, 오늘보다 나은 내일을 위해 노력합니다! 일레클을 통해 새로운 이동으로의 전환을 가속시키겠습니다!"
  },
  {
    id: 39,
    name: "이지원",
    nickname: "rieul",
    position: "iOS Engineer",
    intro: "더 나은 코드를 위해 끊임없이 고민하고 있습니다."
  },
  {
    id: 40,
    name: "신지호",
    nickname: "harrison",
    position: "Android Engineer",
    intro:
      "깔끔한 로직을 추구하고, 사용자의 관점에서 생각하며 개발하는것을 좋아합니다."
  },
  {
    id: 41,
    name: "류명기",
    nickname: "chopa",
    position: "Backend Engineer",
    intro:
      "신뢰할 수 있는 서비스를 위해 다양한 고민과 새로운 도전을 좋아합니다. 꾸준함의 힘을 믿습니다!"
  },
  {
    id: 42,
    name: "박상진",
    nickname: "nathan",
    position: "HR Manager",
    intro:
      "나인투원 팀원 모두가 행복하게 일할 수 있도록 돕는 일을 합니다. 모두 행복하세요!"
  },
  {
    id: 44,
    name: "우호용",
    nickname: "dominico",
    position: "Customer Service Manager",
    intro: "항상 더 좋은 서비스로 불편함이 없도록 생각하고 노력하겠습니다!"
  },
  {
    id: 45,
    name: "박민이",
    nickname: "mininer",
    position: "Operations Planning Manager",
    intro:
      "일레클의 운영 기획을 담당하고 있습니다. 언제 어디서나 일레클을 이용하실 수 있게 하고 싶습니다."
  },
  {
    id: 46,
    name: "김승훈",
    nickname: "frank",
    position: "Business Development Analyst",
    intro:
      "전국에 일레클이 많아질수록 이동의 퀄리티는 높아진다고 믿습니다. 더 많은 지역에서 더 많은 분들이 일레클을 경험하실 수 있기를 바랍니다."
  },
  {
    id: 47,
    name: "임수진",
    nickname: "gia",
    position: "Web Frontend Engineer",
    intro: "새로운 지식 습득을 즐기고 읽기 좋은 코드를 만드는걸 좋아합니다."
  },
  {
    id: 48,
    name: "유아린",
    nickname: "lini",
    position: "Marketing Designer",
    intro:
      "마케팅 디자인을 담당하고 있으며 아이디어를 시각적으로 잘 표현해내기 위해 노력합니다!"
  },
  {
    id: 49,
    name: "홍주리",
    nickname: "dora",
    position: "Data Analyst",
    intro:
      "일레클의 데이터를 분석하고 있습니다. 데이터 속에 답이 있다고 믿고 있습니다!"
  },
  {
    id: 51,
    name: "박병성",
    nickname: "kuma",
    position: "Production Manager",
    intro: "나인투원에서 모빌리티를 효율적으로 생산이 가능하도록 노력합니다."
  },
  {
    id: 52,
    name: "이승연",
    nickname: "lyla",
    position: "Backend Engineer",
    intro:
      "잘 정리된 시스템을 만들기 위해 노력하는 백엔드 개발자입니다. 일레클과 함께 끊임없이 성장하고 싶습니다."
  },
  {
    id: 53,
    name: "서상진",
    nickname: "podong",
    position: "Backend Engineer",
    intro:
      "상상을 현실로 만들기 위해 끊임없이 도전하고, 실패를 통해 얻은 지식으로 안전하고 견고한 서비스 개발을 지향하는 개발자입니다."
  },
  {
    id: 54,
    name: "송기웅",
    nickname: "dune",
    position: "Backend Engineer",
    intro:
      "서버 개발과 인프라 설계에 관심이 있는 개발자입니다. 주변 사람들에게 긍정적인 영향을 줄 수 있는 개발자가 되고 싶습니다. 365일 장애없는 서비스 운영을 목표로 열심히 달려보겠습니다."
  },
  {
    id: 55,
    name: "이지원",
    nickname: "zion",
    position: "Franchise Sales Manager",
    intro: "일레클이 더 많은 사람들에게 닿기를 바랍니다!"
  },
  {
    id: 56,
    name: "이지은",
    nickname: "wiggle",
    position: "Franchise Sales Manager",
    intro:
      "일상 속 힐링과 재미를 주는 일레클 :) 많은 사람들이 경험할 수 있도록 무한 질주하겠습니다"
  },
  {
    id: 57,
    name: "장서윤",
    nickname: "unis",
    position: "Business Development Analyst",
    intro: "데이터를 통해 일레클 유저의 마음을 읽고자 합니다 :)"
  }
];
export const PARTNERS = [
  "molit",
  "mois",
  "seoul",
  "sejong",
  "gimpo",
  "bucheon",
  "jeju",
  "samsung",
  "h-ec",
  "socar",
  "lgcns",
  "ktpowertel"
];

export const ARTICLES = [
  {
    title: "쏘카 공유전기자전거 3기 가맹사업자 모집",
    subtitle: "헤럴드경제 | 2024.01.15",
    link: "https://news.heraldcorp.com/view.php?ud=20240115000351"
  },
  {
    title: "쏘카 공유 퍼스널 모빌리티 플랫폼 일레클, 3기 가맹사업자 모집",
    subtitle: "매일일보 | 2024.01.12",
    link: "https://www.m-i.kr/news/articleView.html?idxno=1084024"
  },
  {
    title:
      "전기자전거 일레클, 가맹사업자 3기 모집…상반기 가맹 규모 1만 대까지 확대",
    subtitle: "서울경제 | 2024.01.12",
    link: "https://www.sedaily.com/NewsView/2D420IAVKO"
  },
  {
    title: "일레클, 3기 가맹사업자 모집…상반기 가맹 운영대수 1만대로",
    subtitle: "연합뉴스 | 2024.01.12",
    link: "https://www.yna.co.kr/view/AKR20240112024900030?input=1195m"
  },
  {
    title: "'이동의 모든 순간을 연결하라'…쏘카, 스트리밍 모빌리티 고도화",
    subtitle: "이데일리 | 2023.10.31",
    link: "https://www.edaily.co.kr/news/read?newsId=01292326635778496&mediaCodeNo=257&OutLnkChk=Y"
  },
  {
    title: "메이트네트웍스, 속초 이어 강릉으로 전기자전거 '일레클' 서비스 확대",
    subtitle: "에너지경제신문 | 2023.09.08",
    link: "https://www.ekn.kr/web/view.php?key=20230908001049034"
  },
  {
    title: "'일레클' 고성장…쏘카, 연간 흑자 기대감 '솔솔'",
    subtitle: "딜사이트 | 2023.08.21",
    link: "https://dealsite.co.kr/articles/108810"
  },
  {
    title: "“전기자전거로만 110억원”...쏘카 일레클 매출 3배 ‘쑥’",
    subtitle: "매일경제 | 2023.02.18",
    link: "https://www.mk.co.kr/news/business/10650077"
  }
];

export const GA_ID = "UA-144258378-1";
