type Settings = {
  API_DOMAIN: string;
  CDN_DOMAIN: string;
  MODE?: string;
  PEOPLES_S3_DOMAIN: string;
};

const ENV_MODE = process.env.NEXT_PUBLIC_MODE;

const devDefaultSettings: Settings = {
  API_DOMAIN: "https://api.elecle.me",
  CDN_DOMAIN: "https://d12plrb6tqz2pw.cloudfront.net/assets",
  PEOPLES_S3_DOMAIN:
    "https://nine2one.s3.ap-northeast-2.amazonaws.com/station/people/data.json"
};

const prodDefaultSettings: Settings = {
  API_DOMAIN: "https://api.elecle.bike",
  CDN_DOMAIN: "https://cdn.elecle.bike/assets",
  PEOPLES_S3_DOMAIN:
    "https://nine2one.s3.ap-northeast-2.amazonaws.com/station/people/data.json"
};

const environmentSettings: { [key: string]: Settings } = {
  development: {
    ...devDefaultSettings,
    MODE: "development"
  },
  production: {
    ...prodDefaultSettings,
    MODE: "production"
  }
};

export const settings: Partial<Settings> = {
  ...environmentSettings[ENV_MODE]
};

export const chatbotSettings = {
  BRAND_KEY: process.env.NEXT_PUBLIC_CHAT_BRAND_KEY,
  CHANNEL_TYPE: process.env.NEXT_PUBLIC_CHAT_CHANNEL_TYPE,
  SCENARIO_ID: process.env.NEXT_PUBLIC_CHAT_SCENARIOD_ID
};
